<template>
  <div>
    <div id="map" :style="styleComputed"></div>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { bbox, center } from '@turf/turf';
import { get } from './cms';
export default {
  props: {
    geojson: {
      type: Object,
      default: null
    },
    geostyle: {
      type: String,
      default: null
    },
    mapStyle: {
      type: String,
      default: 'mapbox://styles/mapbox/outdoors-v11'
    },
    center: {
      type: Array,
      default: null
    },
    zoom: {
      type: Number,
      default: 9
    },
    borderColor: {
      type: String,
      default: '#141414'
    },
    fillColor: {
      type: String,
      default: '#72c5f2'
    },
    height: {
      type: String,
      default: '50vh'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      map: null,
      accessToken:
        'pk.eyJ1IjoiY2xhc3NpY25ldXBhbmUiLCJhIjoiY2wyajVycnNvMHVpMDNtbDVucWlmc2t0aiJ9.zJcUa4pULRxTWr-VuPmDnw',
      queue: [],
      geostyleData: null
    };
  },
  computed: {
    styleComputed() {
      return {
        height: this.height,
        width: this.width
      };
    }
  },
  watch: {
    mapStyle: {
      handler(value) {
        if (this.map && value) {
          this.map.setStyle(value);
        }
      }
    }
  },
  created() {
    Mapbox.accessToken = this.accessToken;
  },
  mounted() {
    this.createMap();
  },
  methods: {
    getCenter(geojson) {
      return center(geojson).geometry.coordinates;
    },
    createMap() {
      const map = new Mapbox.Map({
        container: 'map',
        style: this.mapStyle,
        center: this.center || this.getCenter(this.geojson),
        zoom: this.zoom,
        attributionControl: false
      });
      this.map = map;

      // on map load
      this.map.on('style.load', this.addSources);
      this.map.on('style.load', this.addLayers);
      map.fitBounds(bbox(this.geojson), {
        padding: 20
      });
    },
    addSources() {
      this.map.addSource('geojson', {
        type: 'geojson',
        data: this.geojson
      });
    },
    async addLayers() {
      if (this.geostyle) {
        try {
          const data = await get({ key: this.geostyle, type: 'geostyle' });
          if (data.size) {
            this.geostyleData = data.docs[0].data();
          }
        } catch {}
      }

      this.map.addLayer({
        id: 'fill',
        type: 'fill',
        source: 'geojson',
        paint: this.geostyleData?.fill || {
          'fill-color': this.fillColor,
          'fill-opacity': 0.3
        },
        filter: ['==', '$type', 'Polygon']
      });

      this.map.addLayer({
        id: 'outline',
        type: 'line',
        source: 'geojson',
        paint: this.geostyleData?.line || {
          'line-color': this.borderColor,
          'line-width': 2
        }
      });

      this.map.loadImage(
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        (err, image) => {
          if (err) throw err;
          this.map.addImage('marker', image);
        }
      );

      this.map.addLayer({
        id: 'points',
        type: 'circle',
        source: 'geojson',
        paint: {
          'circle-radius': {
            base: 1.75,
            stops: [
              [12, 5],
              [22, 28]
            ]
          },
          'circle-color': this.geostyleData?.circle?.['circle-color'] || '#025e75'
        },
        filter: ['any', ['==', '$type', 'Point'], ['==', '$type', 'LineString']]
      });
    }
  }
};
</script>

<style>
/* #map {
} */
</style>
